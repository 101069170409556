@font-face {
    font-family: "Open Sans";
    src: local("OpenSans-Regular"), url("./fonts/OpenSans-Regular.woff") format("woff");
}

h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif;
}

/* Override bootstrap sizes */

body {
    font-size: 0.8rem;
    margin-bottom: 40px;
}

.form-control {
    font-size: 0.8rem;
}

.form-select {
    font-size: 0.8rem;
}

.btn {
    font-size: 0.8rem;
}

.dropdown-menu {
    font-size: 0.8rem;
}

.form-check-input {
    font-size: 1.2rem;
    margin-top: 0.5rem;
}

.form-radio-input .form-check-input {
    font-size: 1rem;
    margin-top: 0.1rem;
}

/* END Override bootstrap sizes */

.pagination button {
    border: 0;
    background-color: #fff;
}

.footer {
    box-shadow: 0 -0.25rem .75rem rgba(0, 0, 0, .05);
    background-color: white;
    font-size: 10px;
    color: black;
    text-align: center;
    padding: 0.4rem 1rem;
    width: 100%;
    /* Sticky footer */
    height: 0px;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
}

.link {
    color: #1a6db3;
    cursor: pointer;
    text-decoration: none;
}

.react-draggable .modal-header, .cursor {
    cursor: move;
}

/* Configuration table styles */
.table tr.deleted td {
    color: #aaaaaa;
}

    .table tr.deleted td .nav-link {
        color: #aaaaaa;
    }

.filter-select {
    font-size: 0.8rem;
    width: auto;
    margin-left: 0.5rem;
}

.config-select {
    font-size: 0.8rem;
}


/* Styled bootstrap tabs */
.tabs {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

    .tabs .nav-item button {
        border-bottom: 0px;
        color: #000000;
    }

    .tabs .nav-link {
        border: 0;
    }

        .tabs .nav-link.active {
            border-color: white;
            border-bottom: 2px solid #4189C7;
        }


.tr-subrow {
    background-color: #EBF1FE;
}

.tr-odd {
    background-color: rgba(0, 0, 0, 0.05);
}

.tr-even {
    background-color: white;
}